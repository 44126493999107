<template>
  <PageHeader image="header-3" :title="$lang('title')" :breadcrumbs="$lang('breadcr')" withMobile />
  <!-- section1 -->
  <section class="pt-8 pb-10 bg-blue3">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5 mb-5 mb-lg-0">
          <h2 class="fw-bold text-blue1 mb-4">
            {{ $lang("section1.title") }}
          </h2>
          <p class="text-blue">{{ $lang("section1.text") }}</p>
        </div>
        <div class="col-lg-6 offset-lg-1">
          <ImageContainer fileName="limerick-city" />
        </div>
      </div>
    </div>
  </section>
  <!-- section2 -->
  <section class="floatting-section">
    <div class="container">
      <div class="card card-blue-grad-2">
        <div class="card-body p-5 text-center">
          <p class="m-0 fw-bold"><span class="text-blue3">{{ $lang('section2.text') }}</span>{{ $lang('section2.text1') }} </p>
        </div>
      </div>
    </div>
  </section>
  <!-- section3 -->
  <section class="py-10 bg-blue1">
    <div class="container">
      <div class="row align-items-stretch">
        <div class="col-lg-5 offset-lg-1 order-lg-1 mb-5 mb-lg-0">
          <h2 class="pb-4 text-blue3">{{ $lang("section3.title") }}</h2>
          <p class="text-white">{{ $lang("section3.text") }}</p>
        </div>
        <div class="col-lg-6">
          <VideoContainer
            thumbnailFileName="video-thumb-3"
            videoToken="xFsUWVLDlfY"
            rounded
            class="h-100"
          />
        </div>
      </div>
    </div>
  </section>
  <!-- section4 -->
  <section class="floatting-section">
    <Swiper
      class="swiper-h-100 pb-5"
      :modules="modules"
      :pagination="true"
      loop
      :breakpoints="cardsBreakpoints"
    >
      <SwiperSlide v-for="(c, i) of $lang('section4.cards')" :key="i">
        <CardIcon :icon="c.icon" :title="c.title" :body="c.body" class="h-100 mb-0" />
      </SwiperSlide>
    </Swiper>
  </section>
  <!-- section5 -->
  <section class="pt-10 pb-8 bg-blue4 text-center">
    <h2 class="text-blue2 mb-5">{{ $lang("section5.title") }}</h2>

    <Swiper
      class="pb-5 mb-5"
      :modules="modules"
      :pagination="true"
      loop
      :breakpoints="buildingsBreakpoints"
    >
      <SwiperSlide v-for="(b, i) of buildings" :key="i">
        <ImageContainer :fileName="b.image" background size="500px" class="img-container-rounded" />
      </SwiperSlide>
    </Swiper>

    <button class="btn btn-blue-grad-1 px-5" @click="goTo('contact_us')">
      {{ $lang("section5.button") }}
    </button>
  </section>
  <!-- section6 -->
  <section class="bg-blue2">
    <div class="row">
      <div class="col-lg-6">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9679.679967651948!2d-8.6191282!3d52.6614227!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x485b5d4fbeeae33d%3A0x39095738bda3d075!2sNED%20College%20-%20English%20School%20in%20Limerick!5e0!3m2!1ses-419!2sar!4v1681926940373!5m2!1ses-419!2sar"
          width="100%"
          height="100%"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div class="col-lg-4 offset-lg-1 py-8 px-5 px-lg-0">
        <h2 class="text-green3 mb-5">{{ $lang("section6.title") }}</h2>
        <div class="d-flex align-items-center mb-3">
          <div class="badge-icon mr-3 flex-shrink-0">
            <img :src="`/imgs/svg/icon_bus.svg`" />
          </div>
          <p>{{ $lang('section6.text1') }} <strong> {{ $lang('section6.text2') }}</strong> {{ $lang('section6.text3') }}</p>
        </div>
        <div class="d-flex align-items-center mb-3">
          <div class="badge-icon mr-3 flex-shrink-0">
            <img :src="`/imgs/svg/icon_build.svg`" />
          </div>
          <p>{{ $lang('section6.text4') }} <strong> {{ $lang('section6.text5') }}</strong></p>
        </div>
        <div class="d-flex align-items-center">
          <div class="badge-icon mr-3 flex-shrink-0">
            <img :src="`/imgs/svg/icon_burger.svg`" />
          </div>
          <p>{{ $lang('section6.text6') }} <strong> {{ $lang('section6.text7') }}</strong> {{ $lang('section6.text8') }}</p>
        </div>
      </div>
    </div>
  </section>
  <!-- testimonialsection -->
  <TestimonialSection class="py-8 bg-blue3 text-center">
    <template #title>
      <h2 class="text-blue2 mb-5">{{ $lang("testimonialsection.title") }}</h2>
    </template>
  </TestimonialSection>

  <!-- mapsection -->
  <MapSection>
    <h2 class="fw-semibold text-blue-grad-2 mb-5">
      {{ $lang("mapsection.title") }}
    </h2>

    <p class="text-black3 mb-5"> {{ $lang('mapsection.body') }} <span class="fw-semibold text-green1"> {{ $lang('mapsection.body1') }}</span></p>
    <button class="btn btn-green-grad px-5" @click="goTo('contact_us')">
      {{ $lang("mapsection.button", true) }}
    </button>
  </MapSection>

  <!-- certificationsection -->
  <CertificationsSection class="py-8 bg-blue1 text-center" />
  <!-- section7 -->
  <section class="pt-8 pb-9 bg-blue3 text-center">
    <div class="container">
      <h2 class="text-white mb-5">{{ $lang("section7.title1") }}</h2>

      <div class="row mb-6 justify-content-center text-left">
        <div class="col-lg-6">
          <CardAddresses
            fileName="campus_limerick"
            title="Chapel House"
            street=" "
            neighbour="Chapel Court"
            neighbour2="Limerick"
            eircode="V94 YH6Y"
            phone="+353 (0) 61468571"
          />
        </div>
      </div>

      <h2 class="text-blue1 mb-3">{{ $lang("section7.title2") }}</h2>
      <button class="btn btn-blue-grad-1 px-5" @click="goTo('contact_us')">
        {{ $lang("section7.button") }}
      </button>
    </div>
  </section>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import MapSection from "@/components/MapSection";
import CardIcon from "@/components/CardIcon";
import TestimonialSection from "@/components/TestimonialSection";
import CertificationsSection from "@/components/CertificationsSection";
import CardAddresses from "@/components/CardAddresses";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { Pagination, Navigation } from "swiper";
import "swiper/swiper-bundle.css";

export default {
  components: {
    PageHeader,
    MapSection,
    Swiper,
    SwiperSlide,
    CardIcon,
    TestimonialSection,
    CertificationsSection,
    CardAddresses,
  },
  data: () => ({
    modules: [Pagination, Navigation],

    cardsBreakpoints: {
      0: {
        slidesPerView: 1.25,
        spaceBetween: 10,
        centeredSlides: true,
      },
      768: {
        slidesPerView: 2.8,
        spaceBetween: 10,
        centeredSlides: true,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 20,
        centeredSlides: true,
      },
    },

    buildingsBreakpoints: {
      0: {
        slidesPerView: 1.2,
        spaceBetween: 20,
        centeredSlides: true,
      },
      768: {
        slidesPerView: 2.5,
        spaceBetween: 25,
        centeredSlides: true,
      },
    },
    buildings: [
      { image: "limerick_1" },
      { image: "limerick_2" },
      { image: "limerick_3" },
      { image: "limerick_4" },
      { image: "limerick_5" },
      { image: "limerick_6" },
      { image: "limerick_7" },
      { image: "limerick_8" },
      { image: "limerick_9" },
    ],
  }),
};
</script>

<style></style>
